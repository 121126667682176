import { axiosClient } from "@shared/api";
import {
  IGetAppsWithGroupsParams,
  IGetAppsWithGroupsResponse,
} from "identity-admin-contracts/user-management";

export const getAppsWithGroups = ({
  originApp,
  affiliation,
}: IGetAppsWithGroupsParams) =>
  axiosClient.get<IGetAppsWithGroupsResponse>("/authorization/groups", {
    params: { originApp, affiliation },
  });
