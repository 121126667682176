import {
  IGetAppsWithGroupsByIdParams,
  IGetAppsWithGroupsByIdResponse,
} from "identity-admin-contracts/user-management";
import { axiosClient } from "@shared/api";

export const getAppsWithGroupsById = ({
  originApp,
  targetUserId,
}: IGetAppsWithGroupsByIdParams) =>
  axiosClient.get<IGetAppsWithGroupsByIdResponse>(
    `/authorization/groups/${targetUserId}`,
    {
      params: {
        originApp,
      },
    },
  );
