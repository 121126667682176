import {
  IGetUserDetailsParams,
  IGetUserGroupsResponse,
} from "identity-admin-contracts/user-management";
import { axiosClient } from "@shared/api";

export const getGroups = ({
  appName,
  country,
  userId,
}: IGetUserDetailsParams) =>
  axiosClient.get<IGetUserGroupsResponse>(`/users/${userId}/groups`, {
    params: {
      appName,
      country,
    },
  });
