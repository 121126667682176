import { useAuthenticationService as authService } from "admin-portal-shared-services";
import axios, { AxiosInstance } from "axios";
import { getCountry, getToken } from "helpers";
import { getApiHost } from "services/host/HostService";
import { v4 } from "uuid";
import { BulkActionsEventCodes, FileResponse } from "./types";

export interface IUploadFileResult {
  creationDate: string;
  linkExpirationTime: number;
  metadata: {
    fileName: string;
    purpose: string;
    description: string;
    userId: string;
    status: string;
  };
  title: string;
  type: string;
  url: string;
  urlExpiresAt: string;
}
export interface getBatchActionsFileStatusParams {
  page: number;
  pageSize: number;
  purpose:
    | "identity-management-update-users"
    | "identity-management-bind-accounts-to-users";
  sort?: "ASC" | "DESC";
}

interface PostFileParams {
  code: BulkActionsEventCodes;
  file: File;
  fileName: string;
  description: string;
  actionType?: "LINK_USER" | "CUSTOMER_USER";
}

export const axiosInstance: AxiosInstance = axios.create();

const headers = {
  requestTraceId: v4(),
  Authorization: getToken(),
  country: getCountry(),
};
const getFiles = async (
  params: getBatchActionsFileStatusParams
): FileResponse => {
  const baseUrl = getApiHost();
  const URL = `${baseUrl}/files`;
  try {
    const response = await authService()
      .enhancedAxios(axiosInstance)
      .get(URL, { params, headers });
    return response.data.data;
  } catch (error) {
    return { content: [], pagination: { page: 0, pageSize: 0, total: 0 } };
  }
};

const postBatchActionsFile = async ({
  file,
  fileName,
  description,
  code,
  actionType,
}: PostFileParams): Promise<IUploadFileResult | null> => {
  const formData = new FormData();
  formData.append("uploaded_file", file);
  const headersFile = {
    requestTraceId: v4(),
    Authorization: getToken(),
    country: getCountry(),
    description,
    fileName,
    code,
    title: file.name,
    actionType,
  };
  const baseUrl = getApiHost();
  const URL = `${baseUrl}/files`;
  try {
    const {
      data: { data },
    } = await authService()
      .enhancedAxios(axiosInstance)
      .post(URL, formData, { headers: headersFile });
    return data;
  } catch (erro) {
    return null;
  }
};

export { getFiles, postBatchActionsFile };
