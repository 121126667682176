import { axiosClient } from "@shared/api";
import { IGetRegistrationLinkParams } from "identity-admin-contracts/user-management";

export const getRegistrationLink = async ({
  inviteId,
  userCountry,
  userId,
  userApp,
}: IGetRegistrationLinkParams) =>
  axiosClient.get<string>(`/user-management/registration-link`, {
    params: {
      inviteId,
      userId,
      userCountry,
      userApp,
    },
  });
