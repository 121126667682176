import { axiosClient } from "@shared/api";
import {
  IGetUserAccountsParams,
  IGetUserAccountsResponse,
} from "identity-admin-contracts/user-management";

export const getUserAccounts = ({
  appName,
  country,
  userId,
}: IGetUserAccountsParams) =>
  axiosClient.get<IGetUserAccountsResponse>(`users/${userId}/accounts`, {
    params: {
      appName,
      country,
    },
  });
