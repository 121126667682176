import { styled } from "@bees/vision-tokens";
import {
  Alert as AlertHexa,
  Heading,
  Paragraph,
  Radio,
} from "@hexa-ui/components";

export const SelectTypeLabel = styled(Heading, {
  "&&": {
    fontSize: "1rem",
    marginTop: "1rem",
  },
});

export const Content = styled("div", {
  marginTop: "1rem",
});

export const Alert = styled(AlertHexa, {
  "&&": {
    width: "100%",
  },
});

export const InputFileContainer = styled("div", {
  variants: {
    type: {
      fileSelected: {
        margin: "1rem 0rem",
      },
    },
  },

  width: "100%",
  height: "19.75rem",
  margin: "0 auto",
  display: "flex",
  justifyContent: "center",
  "& .dropzone-ui > section > h2": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "$4",
  },
});

export const DownloadTemplate = styled("a", {
  position: "absolute",
  bottom: "1.615rem",
  height: "1.5rem",
  width: "9.75rem",
  color: "$semanticInfoText",
  fontFamily: "Work Sans",
  fontSize: "1rem",
  letterSpacing: "0.006rem",
  lineHeight: "1.5rem",
});

export const CustomerUploaderWrapper = styled("div", {
  a: {
    color: "$semanticInfoText",
  },
  "[aria-disabled=true]": {
    color: "$neutral50",
    pointerEvents: "none",
    textDecoration: "none",
  },
});

export const CsvLinkButtonContainer = styled("div", {
  "& a": {
    alignItems: "center",
    display: "flex",
    color: "rgba(0, 110, 219, 1)",
    fontSize: "1rem",
    textDecoration: "unset",

    "&:hover": {
      textDecoration: "underline",
    },
    padding: "0.5rem 1rem",

    "&[aria-disabled=true]": {
      height: "2.5rem",
      width: "fit-content",
      backgroundColor: "rgba(20, 20, 20, 0.08)",
      borderRadius: "1rem",
      color: "rgba(20, 20, 20, 0.32)",
    },
    fontWeight: "$medium",
  },
  "& svg": {
    marginRight: "0.5rem",
  },
});

export const InfoMessage = styled(Paragraph, {
  "&&": {
    color: "rgba(117, 117, 117, 1)",
    marginBottom: "1rem",
  },
});

export const SelectTypeGroup = styled(Radio.Root, {
  display: "flex",
  direction: "column",
  gap: "1.5rem",
});

export const AlertMessageContainer = styled("div", {
  paddingBottom: "1rem",
});
