import { Toast, useToast } from "components/Toast";
import { UserDetailsProvider } from "contexts/useUserDetails/provider/userDetailsProvider";
import { BatchActionsPage } from "pages/BatchActions";
import { UserDetails } from "pages/UserDetails/UserDetails";
import { UsersPage } from "pages/Users/Users";
import React, { useEffect, lazy } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

const UserManagement = lazy(
  () => import("identity-admin-mfe/modules/user-management")
);

export interface RouterProps {
  canViewBulkActionsPage: boolean;
  canViewPermissionsPage: boolean;
  canViewPocPage: boolean;
  canViewUsersPage: boolean;
  canViewLinkAccountsPage: boolean;
}

export const Router = ({
  canViewBulkActionsPage,
  canViewPermissionsPage,
  canViewPocPage,
  canViewUsersPage,
  canViewLinkAccountsPage,
}: RouterProps): JSX.Element => {
  const { toastOptions } = useToast();
  const history = useHistory();

  useEffect(() => {
    const redirect = (): void => {
      if (canViewUsersPage) {
        return;
      }

      if (canViewPocPage) {
        history.push("/accounts");
      }

      if (canViewBulkActionsPage) {
        history.push("/users/bulk-actions");
      }

      if (canViewPermissionsPage) {
        history.push("/permissions");
      }

      if (canViewLinkAccountsPage) {
        history.push("/accounts/link-accounts");
      }
    };

    redirect();
  }, [
    canViewBulkActionsPage,
    canViewLinkAccountsPage,
    canViewPermissionsPage,
    canViewPocPage,
    canViewUsersPage,
    history,
  ]);

  return (
    <>
      <Toast {...toastOptions} />
      <Switch>
        {/* Attention, it is important to centralize the user details in the context, when it is necessary to use the LoggedUserContext activate here. */}
        <Route exact path="/users/users-old" component={UsersPage} />
        <Route exact path="/users/bulk-actions" component={BatchActionsPage} />
        <UserDetailsProvider>
          <Route exact path="/users/user-details-old" component={UserDetails} />
          <UserManagement />
        </UserDetailsProvider>
      </Switch>
    </>
  );
};
