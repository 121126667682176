import {
  Button,
  Heading,
  FileValidated as ValidatedFile,
} from "@hexa-ui/components";
import { Dialog } from "components/Dialog";
import { TOAST_POSITION, TOAST_SEVERITY, useToast } from "components/Toast";
import { SegmentService, getDecodedToken } from "helpers";
import { countFileRows, reader } from "helpers/FileStream/FileStream";
import { FileType } from "identity-admin-mfe/modules/user-management/services/AnalyticService";
import React, { useMemo, useState } from "react";
import {
  BulkActionsEventCodes,
  postBatchActionsFile,
} from "services/batchActions";
import { AdminUserUploadFile } from "./AdminUserUploadFile";
import { CustomerUserUploadFile } from "./CustomerUserUploadFile";
import { Alert, Content } from "./styles";
import {
  CustomerUserActionType,
  DialogUploadFileProps,
  FileTypes,
  FileUploadErrors,
} from "./types";

const ROW_LIMIT = {
  [FileTypes.Authorization]: 200,
  [FileTypes.Invitation]: 200,
};

export const DialogUploadFile = ({
  open,
  onClose,
  onConfirm,
  fileType = FileTypes.Authorization,
}: DialogUploadFileProps): JSX.Element => {
  const { setToastOptions } = useToast();
  const { userId } = getDecodedToken();
  const [file, setFile] = useState<File>();
  const [fileDescription, setFileDescription] = useState<string>("");
  const [fileUploadError, setFileUploadError] = useState<
    FileUploadErrors | undefined
  >(undefined);
  const [actionType, setActionType] = useState<CustomerUserActionType>();

  const fileTypeSegment =
    fileType === FileTypes.Authorization
      ? FileType.EditAdminUser
      : FileType.ManageCustomerUser;

  const handleCancelFileUpload = (hasCancelButtonBeenPressed: boolean) => {
    SegmentService.newUploadedCanceled(
      hasCancelButtonBeenPressed,
      fileTypeSegment
    );
    handleCloseFileDialog();
  };

  const handleCloseFileDialog = () => {
    onClose();
    setFile(undefined);
    setFileUploadError(undefined);
    setFileDescription("");
    setActionType(undefined);
  };

  const handleOnInteractOutside = () => {
    setToastOptions({
      type: TOAST_SEVERITY.WARNING,
      showCloseButton: true,
      message: "Close upload file modal?",
      duration: 5000,
      actions: [
        {
          action: () => handleCancelFileUpload(false),
          name: "Close",
        },
      ],
    });
  };

  const getVendorId = async (csv: File) => {
    const parsedFileData = await reader(csv);
    const vendorId = parsedFileData.split(",")[8];
    return vendorId;
  };

  const handleUploadFile = async () => {
    if (!file) return;

    const fileName = `${userId}-${new Date().getTime()}.csv`;
    const code =
      fileType === FileTypes.Authorization
        ? BulkActionsEventCodes.AuthEvent
        : BulkActionsEventCodes.InvitationEvent;
    const result = await postBatchActionsFile({
      file,
      fileName,
      description: fileDescription,
      code,
      actionType,
    });

    const rowsNumber = await countFileRows(file);

    SegmentService.newUploadSubmitted({
      fileTitle: file.name,
      fileDescription,
      rowsNumber,
      fileType: fileTypeSegment,
      vendorId:
        fileTypeSegment === FileType.ManageCustomerUser
          ? await getVendorId(file)
          : "",
    });
    if (result) {
      onConfirm(fileName);
      setToastOptions({
        type: TOAST_SEVERITY.SUCCESS,
        message: "File successfully uploaded.",
        duration: 5000,
        position: TOAST_POSITION.TOP_RIGHT,
      });
    } else {
      setToastOptions({
        type: TOAST_SEVERITY.ERROR,
        message: "Unavailable service. Please try again later.",
        duration: 5000,
        position: TOAST_POSITION.TOP_RIGHT,
      });
    }

    handleCloseFileDialog();
  };

  const isValidFile = async (
    preValidatedFile: ValidatedFile
  ): Promise<boolean> => {
    const { file: fileContent, valid, errors } = preValidatedFile;
    const hasTooManyLines = await countFileRows(fileContent).then(
      (rowCount) => rowCount >= ROW_LIMIT[fileType]
    );
    if (!valid || hasTooManyLines) {
      SegmentService.fileUploaded(false, fileTypeSegment);
      setFileUploadError({
        message: hasTooManyLines
          ? `The file exceeds the ${ROW_LIMIT[fileType]} rows limit.`
          : errors?.toString() ?? "",
      });

      return false;
    }

    SegmentService.fileUploaded(true, fileTypeSegment);
    setFileUploadError(undefined);
    return true;
  };

  const handleOnChangeFile = async ([preValidatedFile]: ValidatedFile[]) => {
    const { file: fileContent } = preValidatedFile;
    const isValid = await isValidFile(preValidatedFile);

    if (isValid) setFile(fileContent);
  };

  const disabledUploadButton = useMemo(() => {
    const hasInvalidFields =
      !file || fileUploadError !== undefined || !fileDescription;
    return fileType === FileTypes.Invitation
      ? hasInvalidFields || !actionType
      : hasInvalidFields;
  }, [actionType, file, fileDescription, fileType, fileUploadError]);

  const onClearFileField = () => {
    setFile(undefined);
    setFileUploadError(undefined);
  };

  const commonProps = {
    handleOnChangeFile,
    setFileDescription,
    file,
    fileUploadError,
    onClearFileField,
  };

  const uploaderByType = {
    [FileTypes.Invitation]: (
      <CustomerUserUploadFile
        {...commonProps}
        setActionType={setActionType}
        actionType={actionType}
      />
    ),
    [FileTypes.Authorization]: <AdminUserUploadFile {...commonProps} />,
  };

  const alertMessage = `There is a limit of ${ROW_LIMIT[fileType]} rows per CSV file.`;

  return (
    <Dialog
      open={open}
      contentCss={{ width: "688px" }}
      title={<Heading size="H2">Upload CSV file</Heading>}
      onClose={() => handleCancelFileUpload(false)}
      onEscapeKeyDown={handleOnInteractOutside}
      onInteractOutside={handleOnInteractOutside}
      actions={{
        btnSecundary: (
          <Button
            onClick={() => handleCancelFileUpload(true)}
            size="medium"
            variant="secondary"
          >
            Cancel
          </Button>
        ),
        btnPrimary: (
          <Button
            disabled={disabledUploadButton}
            onClick={handleUploadFile}
            size="medium"
            variant="primary"
          >
            Upload
          </Button>
        ),
      }}
    >
      <Content>
        <Alert message={alertMessage} />
        {uploaderByType[fileType]}
      </Content>
    </Dialog>
  );
};
