import {
  FileUploader,
  TextArea,
  FileValidated as ValidatedFile,
} from "@hexa-ui/components";
import React from "react";
import { InputFileContainer } from "./styles";
import { FileUploadErrors } from "./types";

interface IFileInputs {
  handleOnChangeFile: ([preValidatedFile]: ValidatedFile[]) => void;
  setFileDescription: (description: string) => void;
  fileUploadError?: FileUploadErrors;
  file?: File;
  onClearFileField: () => void;
}

const MAX_FILE_SIZE_ALLOWED = 30720; // 30KB

export const FileInputs = ({
  setFileDescription,
  file,
  onClearFileField,
  handleOnChangeFile,
  fileUploadError,
}: IFileInputs): JSX.Element => {
  return (
    <>
      <InputFileContainer type={file && "fileSelected"}>
        <FileUploader
          behaviour="replace"
          accept=".csv"
          type={file?.name ? "file" : "document"}
          maxFileSize={MAX_FILE_SIZE_ALLOWED}
          maxFiles={1}
          fakeUploading
          message={
            file?.name
              ? `${file?.name} - ${file?.size / 1000}KB`
              : "Drop your CSV file here or"
          }
          onChange={handleOnChangeFile}
          error={fileUploadError}
          onClean={onClearFileField}
        />
      </InputFileContainer>
      <TextArea
        id="text-area-with-label"
        characterCounter
        onChange={(event) => {
          setFileDescription((event.target as HTMLTextAreaElement).value);
        }}
        maxLength={100}
        label="Description"
        placeholder="Example: Remove inactive users from application. "
        width="100%"
      />
    </>
  );
};
