import { axiosClient } from "@shared/api";
import { IGetUserVendors } from "identity-admin-contracts/user-management";

export const getUserVendors = ({ appName, country, userId }: IGetUserVendors) =>
  axiosClient.get(`users/${userId}/vendors`, {
    params: {
      appName,
      country,
    },
  });
