import { axiosClient } from "@shared/api";
import { IPutAdditionalInformationParams } from "identity-admin-contracts/user-management";

export const putAdditionalInformation = ({
  id,
  appName,
  country,
  additionalInformation,
}: IPutAdditionalInformationParams) =>
  axiosClient.put(
    `/users/${id}/additional-information`,
    {
      ...additionalInformation,
    },
    {
      params: {
        appName,
        country,
      },
    },
  );
