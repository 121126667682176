import { axiosClient } from "@shared/api";
import { IGetVendorsAvailableResponse } from "identity-admin-contracts/user-management";

interface IGetVendorsProps {
  countries: string[];
}

export const getAvailableVendors = ({ countries }: IGetVendorsProps) =>
  axiosClient.get<IGetVendorsAvailableResponse>(`/users/me/vendors`, {
    params: { countries: countries.join(",") },
  });
