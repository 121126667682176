import { axiosClient } from "@shared/api";
import { IGetUserAvailableCountriesResponse } from "identity-admin-contracts/user-management";

export const getUserAvailableCountries = (acceptLanguage: string) =>
  axiosClient.get<IGetUserAvailableCountriesResponse>(
    "/users/me/available-countries",
    {
      headers: {
        "accept-language": acceptLanguage,
      },
    },
  );
