import { axiosClient } from "@shared/api";
import { IPutGroupsParams } from "identity-admin-contracts/user-management";

export const putGroups = ({ id, appName, country, groups }: IPutGroupsParams) =>
  axiosClient.put(
    `/users/${id}/groups`,
    {
      groups,
    },
    {
      params: {
        appName,
        country,
      },
    },
  );
