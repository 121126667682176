import { axiosClient } from "@shared/api";
import { IPutVendorsMutationParams } from "identity-admin-contracts/user-management";

export const putVendors = ({
  id,
  appName,
  country,
  vendorsIds,
}: IPutVendorsMutationParams) =>
  axiosClient.put(
    `/users/${id}/vendors`,
    { vendorsIds },
    {
      params: {
        appName,
        country,
      },
    },
  );
