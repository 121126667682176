import { axiosClient } from "@shared/api";
import {
  IGetAuditLogsInput,
  IGetAuditLogsResponse,
} from "identity-admin-contracts/user-management";

export const getAuditLogs = ({
  userId,
  status,
  country,
  appName,
  page,
  acceptLanguage,
  timeZone,
}: IGetAuditLogsInput) =>
  axiosClient.get<IGetAuditLogsResponse>(
    `/users/${userId}/recent-activities/${status}`,
    {
      params: { country, page, appName },
      headers: {
        "Accept-Language": acceptLanguage,
        "Time-Zone": timeZone,
      },
    },
  );
