import csvTemplate from "assets/csv/bulk-actions-admin-users-csv-template.csv";
import { SegmentService } from "helpers";
import { FileType } from "identity-admin-mfe/modules/user-management/services/AnalyticService";
import React from "react";

import { FileInputs } from "./FileInputs";
import { Content, DownloadTemplate } from "./styles";
import { IUploaderFileProps } from "./types";

type IAdminUserUploadFileProps = IUploaderFileProps;

export const AdminUserUploadFile = ({
  handleOnChangeFile,
  file,
  fileUploadError,
  setFileDescription,
  onClearFileField,
}: IAdminUserUploadFileProps): JSX.Element => {
  const handleTemplateDownload = () =>
    SegmentService.bulkActionsTemplateDownloadedModal(FileType.EditAdminUser);

  return (
    <Content>
      <FileInputs
        handleOnChangeFile={handleOnChangeFile}
        setFileDescription={setFileDescription}
        file={file}
        fileUploadError={fileUploadError}
        onClearFileField={onClearFileField}
      />

      <DownloadTemplate
        href={csvTemplate}
        onClick={handleTemplateDownload}
        download
      >
        Download template
      </DownloadTemplate>
    </Content>
  );
};
