import { IPostResendCustomerInviteParams } from "identity-admin-contracts/user-management";
import { axiosClient } from "@shared/api";

export const postResendCustomerInvite = ({
  userId,
  country,
  appName,
  invitedUserName,
  requesterUserName,
  accountsName,
  wholesalerName,
  wholesalerId,
}: IPostResendCustomerInviteParams) => {
  return axiosClient.post<string>(
    `/user-management/resend-customer-invite/${userId}`,
    {
      invitedUserName,
      requesterUserName,
      wholesalerName,
      accountsName,
      wholesalerId,
    },
    {
      params: { country, appName },
    },
  );
};
