import { axiosClient } from "@shared/api";

type UserEditMutationType = {
  editData: {
    firstName?: string;
    lastName?: string;
    affiliation?: string | null;
    manager?: string;
    supervisors?: string[];
  };
  country?: string;
  appName: string;
  userId: string;
};

export const patchUserEdit = async ({
  userId,
  appName,
  country,
  editData,
}: UserEditMutationType) => {
  const response = await axiosClient.patch(
    `/users/${userId}/info`,
    {
      ...editData,
    },
    {
      params: { appName, country },
    },
  );

  return response;
};
