import { axiosClient } from "@shared/api";
import { Vendor } from "identity-admin-contracts/user-management";

type GetVendorsByNameProps = {
  name: string;
  countries: string[];
  isManufacturer: boolean;
};

export const getVendorsByName = ({
  name,
  countries,
  isManufacturer,
}: GetVendorsByNameProps) =>
  axiosClient.get<Pick<Vendor, "name" | "vendorId" | "country">[]>(
    `/vendors/name/${name}`,
    {
      params: { countries: countries.join(","), isManufacturer },
    },
  );
