import { Breadcrumb, Tabs } from "@hexa-ui/components";
import { useHasPermission } from "admin-portal-shared-services";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { TableBatchActions } from "./Components/TableBatchActions/TableBatchActions";
import { TabsBatchActionEnum } from "./Components/TableBatchActions/types";
import { HeaderActions, HeaderTitle, Panel, Wrapper } from "./styles";

const TabList = (): React.ReactNode => {
  const hasAccessToAdminUsers = useHasPermission(
    ["IdentityFE.ONEUser.Write", "IdentityFE.ForceUser.Write"],
    "OR"
  );
  const hasUserWritePermission = useHasPermission(["Identity.User.Write"]);
  const canViewAdminTab = hasAccessToAdminUsers && hasUserWritePermission;

  const canViewCustomerTab = useHasPermission(
    ["IdentityFE.UserPOC.Write", "IdentityFE.CustomerUser.Write"],
    "OR"
  );

  const tabs: React.ReactNode[] = [];
  if (canViewAdminTab) {
    tabs.push(
      <Tabs.Trigger
        key={TabsBatchActionEnum.editAdminUsers}
        hidden
        value={TabsBatchActionEnum.editAdminUsers}
      >
        Edit admin users
      </Tabs.Trigger>
    );
  }
  if (canViewCustomerTab) {
    tabs.push(
      <Tabs.Trigger
        key={TabsBatchActionEnum.manageCustomerUsers}
        value={TabsBatchActionEnum.manageCustomerUsers}
      >
        Manage customer users
      </Tabs.Trigger>
    );
  }

  return tabs;
};

export const BatchActionsPage = (): JSX.Element => {
  const history = useHistory();
  const hasPermissionToBatchActions = useHasPermission(
    "IdentityFE.BatchAction.Write"
  );
  const canViewAdminTab = useHasPermission([
    "IdentityFE.ONEUser.Write",
    "Identity.User.Write",
  ]);

  const canViewCustomerTab = useHasPermission(
    ["IdentityFE.UserPOC.Write", "IdentityFE.CustomerUser.Write"],
    "OR"
  );

  useEffect(() => {
    if (!hasPermissionToBatchActions) history.push("/error/403");
  }, [hasPermissionToBatchActions, history]);

  const BreadcrumbPage = (): JSX.Element => (
    <Breadcrumb.Root>
      <Breadcrumb.HomeItem asChild>
        <a href="#void" onClick={history.goBack}>
          Home
        </a>
      </Breadcrumb.HomeItem>
      <Breadcrumb.Item asChild isCurrentPage>
        <a href="/users/bulk-actions">Bulk actions</a>
      </Breadcrumb.Item>
    </Breadcrumb.Root>
  );

  return (
    <Wrapper className="container_mfe">
      <BreadcrumbPage />
      <HeaderActions>
        <HeaderTitle>Bulk actions</HeaderTitle>
      </HeaderActions>
      <Panel elevated="small" border="medium">
        <Tabs.Root
          defaultValue={
            canViewAdminTab
              ? TabsBatchActionEnum.editAdminUsers
              : TabsBatchActionEnum.manageCustomerUsers
          }
        >
          <Tabs.List>{TabList()}</Tabs.List>
          {canViewAdminTab && (
            <Tabs.Content value={TabsBatchActionEnum.editAdminUsers}>
              <TableBatchActions
                activeTab={TabsBatchActionEnum.editAdminUsers}
              />
            </Tabs.Content>
          )}
          {canViewCustomerTab && (
            <Tabs.Content value={TabsBatchActionEnum.manageCustomerUsers}>
              <TableBatchActions
                activeTab={TabsBatchActionEnum.manageCustomerUsers}
              />
            </Tabs.Content>
          )}
        </Tabs.Root>
      </Panel>
    </Wrapper>
  );
};
