import {
  Checklist1 as Checklist,
  Clipboard,
  Shield2,
  Users,
  Store,
} from "@admin-portal-shared-components/icons";
import {
  useAnalyticsService,
  useGetEnvPropPerCluster,
  useHasPermission,
  useSidebar,
  useGetCluster,
} from "admin-portal-shared-services";
import React, { useMemo } from "react";
import { Router } from "../Router";

export const menuIcons = {
  clipboard: Clipboard,
  checklist: Checklist,
  users: Users,
  shield: Shield2,
  store: Store,
};

export const AppConfig = (): JSX.Element => {
  const segmentKey = String(useGetEnvPropPerCluster("segmentKey"));
  const analyticsService = useAnalyticsService();
  const hasUserReadPermission = useHasPermission("IdentityFE.User.Read");
  const hasUserWritePermission = useHasPermission([
    "IdentityFE.User.Write",
    "Identity.User.Write",
  ]);
  const hasCustomerUserReadPermission = useHasPermission(
    ["IdentityFE.CustomerUser.Read", "Identity.User.Read"],
    "AND"
  );
  const hasPOCReadPermission = useHasPermission("IdentityFE.POC.Read");
  const hasPermissionToBatchActions = useHasPermission(
    "IdentityFE.BatchAction.Write"
  );
  const hasPermissionWrite = useHasPermission("IdentityFE.Permission.Write");
  const hasPermissionRead = useHasPermission("IdentityFE.Permission.Read");
  const hasLinkManagementWritePermission = useHasPermission(
    "IdentityFE.LinkManagement.Execute"
  );
  const cluster = useGetCluster();
  const isGlobal = cluster === "Global_US";

  const canViewUsersPage =
    hasUserReadPermission ||
    hasUserWritePermission ||
    hasCustomerUserReadPermission;
  const canViewBulkActionsPage = hasPermissionToBatchActions;
  const canViewPocPage = hasPOCReadPermission;
  const canViewPermissionsPage = hasPermissionWrite || hasPermissionRead;
  const canViewLinkAccountsPage = hasLinkManagementWritePermission && isGlobal;

  analyticsService.load(segmentKey);

  const memoListMenuWithAccess = useMemo(
    () =>
      [
        {
          id: "1",
          title: "Accounts",
          icon: menuIcons.clipboard,
          path: "/accounts",
          hasAccess: canViewPocPage,
        },
        {
          id: "2",
          title: "Users",
          icon: menuIcons.users,
          path: "/users",
          hasAccess: canViewUsersPage,
        },
        {
          id: "3",
          title: "Bulk actions",
          icon: menuIcons.checklist,
          path: "/users/bulk-actions",
          hasAccess: canViewBulkActionsPage,
        },
        {
          id: "4",
          title: "Roles & Permissions",
          icon: menuIcons.shield,
          path: "/permissions",
          hasAccess: canViewPermissionsPage,
        },
        {
          id: "5",
          title: "Accounts linking",
          icon: menuIcons.store,
          path: "/accounts/link-accounts",
          hasAccess: canViewLinkAccountsPage,
        },
      ].filter((listItem) => listItem.hasAccess),
    [
      canViewBulkActionsPage,
      canViewLinkAccountsPage,
      canViewPermissionsPage,
      canViewPocPage,
      canViewUsersPage,
    ]
  );

  const memoSidebarParams = useMemo(
    () => ({
      items: memoListMenuWithAccess,
      utils: [],
    }),
    [memoListMenuWithAccess]
  );

  useSidebar(memoSidebarParams);

  return (
    <Router
      canViewBulkActionsPage={canViewBulkActionsPage}
      canViewPermissionsPage={canViewPermissionsPage}
      canViewPocPage={canViewPocPage}
      canViewUsersPage={canViewUsersPage}
      canViewLinkAccountsPage={canViewLinkAccountsPage}
    />
  );
};
