import { axiosClient } from "@shared/api";
import { IDeleteUserAccountsProps } from "identity-admin-contracts/user-management";

export const deleteUserAccounts = ({
  userId,
  country,
  appName,
  accountIds,
}: IDeleteUserAccountsProps) =>
  axiosClient.delete<void>(`/users/${userId}/accounts`, {
    data: {
      country,
      appName,
      accountIds,
    },
  });
