import { IPostResendAdminInviteParams } from "identity-admin-contracts/user-management";
import { axiosClient } from "@shared/api";

export const postResendAdminInvite = ({
  userId,
  country,
  appName,
  invitedUserName,
  requesterUserName,
}: IPostResendAdminInviteParams) => {
  return axiosClient.post<string>(
    `/user-management/resend-admin-invite/${userId}`,
    {
      invitedUserName,
      requesterUserName,
    },
    {
      params: { country, appName },
    },
  );
};
