import { axiosClient } from "@shared/api";
import { IPutSupportedCountriesParams } from "identity-admin-contracts/user-management";

export const putSupportedCountries = ({
  id,
  appName,
  country,
  countries,
}: IPutSupportedCountriesParams) =>
  axiosClient.put(
    `/users/${id}/supported-countries`,
    { countries },
    {
      params: {
        appName,
        country,
      },
    },
  );
