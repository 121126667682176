import {
  Download,
  Plus,
  RotateCw,
} from "@admin-portal-shared-components/icons";
import {
  IconButton,
  PaginationProps,
  Paragraph,
  Tooltip,
} from "@hexa-ui/components";
import {
  FileType,
  ScreenName,
} from "identity-admin-mfe/modules/user-management/services/AnalyticService";
import csvTemplate from "assets/csv/bulk-actions-admin-users-csv-template.csv";
import { Button } from "components/Button";
import { Table } from "components/Table";
import { addDays } from "date-fns";
import { SegmentService } from "helpers";
import { formatDate } from "helpers/FormatDate/FormatDate";
import { ButtonsContainer } from "pages/UserDetails/styles";
import React, { useEffect, useState } from "react";
import { FileData, FileInfo, FileStatus } from "services/batchActions";
import { getFiles } from "services/batchActions/BatchActionsService";
import { useLocalStorageService } from "services/localStorage";
import { DialogUploadFile, FileTypes } from "../DialogUploadFile";
import { EmptyState } from "../EmptyState";
import {
  FailIcon,
  HeaderActions,
  PartiallySucessfulIcon,
  SucessfulIcon,
  UpdateTimeDisplay,
} from "./styles";
import { DialogUploadFileProps, TabsBatchActionEnum } from "./types";

const FILES_AWAITING_FEEDBACK = "files-awaiting-feedback";

export const FetchFilesPurposes = {
  [TabsBatchActionEnum.editAdminUsers]: "identity-management-update-users",
  [TabsBatchActionEnum.manageCustomerUsers]:
    "identity-management-bind-accounts-to-users",
} as const;

const FileTypesByTab = {
  [TabsBatchActionEnum.editAdminUsers]: FileTypes.Authorization,
  [TabsBatchActionEnum.manageCustomerUsers]: FileTypes.Invitation,
} as const;

export const TableBatchActions = ({
  activeTab,
}: DialogUploadFileProps): JSX.Element => {
  const localStorageService = useLocalStorageService();
  const [isLoading, setIsLoading] = useState(false);
  const [intervalBatchActionsId, setIntervalBatchActionsId] =
    useState<NodeJS.Timeout>();
  const updateIntervalFileData = 60 * 1000; // one minute
  const [timeOfNextUpdate, setTimeOfNextUpdate] = useState(0);
  const [updateDate, setUpdateDate] = useState<Date>(new Date());
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [fileData, setFileData] = useState<FileData>({
    content: [],
    pagination: { total: 0, page: 0, pageSize: 50 },
  });

  const { content, pagination } = fileData;

  const fileTypeSegment =
    activeTab === TabsBatchActionEnum.editAdminUsers
      ? FileType.EditAdminUser
      : FileType.ManageCustomerUser;

  const handleTimeOutRefresh = (page?: number) => {
    const pageParam = page ?? pagination.page;
    clearInterval(intervalBatchActionsId);
    const intervalId: NodeJS.Timeout = setInterval(() => {
      handleGetFileInfoData(pageParam);
    }, updateIntervalFileData);
    setIntervalBatchActionsId(intervalId);
    setTimeOfNextUpdate(new Date().getTime() + updateIntervalFileData / 2);
  };

  const handleFakeUpdateFileData = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleUpdateFileData = () => {
    SegmentService.bulkActionsRefreshedPage(fileTypeSegment);
    const shouldUpdate: boolean = timeOfNextUpdate < new Date().getTime();
    if (shouldUpdate) {
      handleGetFileInfoData();
      handleTimeOutRefresh();
    } else {
      setUpdateDate(new Date());
      handleFakeUpdateFileData();
    }
  };

  const trackFileFinalStatus = (updatedFileList: FileInfo[]) => {
    const filesToTrack =
      (localStorageService.getValue(FILES_AWAITING_FEEDBACK) as string[]) || [];
    const trackedFiles = updatedFileList.reduce((acc: string[], file) => {
      if (!filesToTrack?.includes(file.metadata.fileName)) return acc;

      SegmentService.newUploadSubmissionResult({
        creation_date: file.creationDate,
        description: file.metadata.description,
        file_name: file.metadata.fileName,
        screen_name: ScreenName.Bulk,
        status_upload: file.metadata.status,
        title_name: file.metadata.fileName,
        url_download: file.url,
        file_type: fileTypeSegment,
      });

      return [...acc, file.metadata.fileName];
    }, []);

    localStorageService.storeValue(
      FILES_AWAITING_FEEDBACK,
      (payload: string[]) =>
        payload?.filter((fileName: string) => !trackedFiles.includes(fileName))
    );
  };

  const handleGetFileInfoData = async (page?: number) => {
    const pageParam = page ?? pagination.page;
    setIsLoading(true);
    const data = await getFiles({
      page: pageParam,
      pageSize: pagination.pageSize,
      purpose: FetchFilesPurposes[activeTab],
    });

    trackFileFinalStatus(data.content);

    setIsLoading(false);
    setUpdateDate(new Date());
    setFileData(data);
    SegmentService.tabSelected(fileTypeSegment, data.content.length);
  };

  const handleGetFileFirstData = async (page?: number) => {
    const pageParam = page ?? pagination.page;
    setIsLoading(true);
    const data = await getFiles({
      page: pageParam,
      pageSize: pagination.pageSize,
      purpose: FetchFilesPurposes[activeTab],
    });
    trackFileFinalStatus(data.content);

    setIsLoading(false);
    setUpdateDate(new Date());
    setFileData(data);
    SegmentService.bulkActionsPageSelected({
      listed_items: data.content.length,
      page_number: data.pagination.page + 1,
      screen_name: ScreenName.Bulk,
      file_type: fileTypeSegment,
    });
    SegmentService.bulkActionsViewed(data.content.length, fileTypeSegment);
  };

  const handleGetFileInfoByPage = (page: number) => {
    const pageParam = page - 1;
    handleGetFileInfoData(pageParam);
    handleTimeOutRefresh(pageParam);
  };

  const tablePagination: PaginationProps = {
    total: pagination.total,
    current: pagination.page + 1,
    pageSize: pagination.pageSize,
    onChange: handleGetFileInfoByPage,
  };

  const statusIcon: Record<FileStatus, JSX.Element> = {
    [FileStatus.PARTIALLY_SUCCESSFUL]: (
      <PartiallySucessfulIcon size="xsmall">
        Partially Sucessfull
      </PartiallySucessfulIcon>
    ),
    [FileStatus.FAILED]: <FailIcon size="xsmall">Failed</FailIcon>,
    [FileStatus.SUCCESSFUL]: (
      <SucessfulIcon size="xsmall">Sucessfull</SucessfulIcon>
    ),
    [FileStatus.PROCESSING]: <Paragraph size="xsmall">Processing...</Paragraph>,
  };

  const handleOpenFileDialog = () => {
    SegmentService.newUploadStarted(fileTypeSegment);
    setOpenFileDialog(true);
  };

  const handleCloseFileDialog = () => {
    setOpenFileDialog(false);
  };

  const onConfirmUploadFile = async (fileName: string) => {
    await handleGetFileInfoData();
    await handleTimeOutRefresh();

    localStorageService.storeValue(
      FILES_AWAITING_FEEDBACK,
      (payload: string[]) => [...(payload || []), fileName]
    );
  };

  useEffect(() => {
    handleGetFileFirstData();
    handleTimeOutRefresh();

    return () => clearInterval(intervalBatchActionsId);
  }, []);

  const HeaderPage = (): JSX.Element => {
    const PlusIcon = () => <Plus size="medium" />;
    const UpdateIcon = () => <RotateCw size="large" />;
    return (
      <HeaderActions>
        <ButtonsContainer>
          <Tooltip placement="left" text="Refresh list">
            <IconButton
              aria-label="refresh list"
              disabled={isLoading}
              onClick={handleUpdateFileData}
              variant="secondary"
              icon={UpdateIcon}
            />
          </Tooltip>
          <Button
            onClick={handleOpenFileDialog}
            leading
            size="medium"
            variant="primary"
            icon={PlusIcon}
          >
            Upload CSV file
          </Button>
        </ButtonsContainer>
      </HeaderActions>
    );
  };

  const adminTableColumns = [
    {
      Header: "Created on",
      accessor: "creationDate",
      disableSortBy: true,
      style: {
        width: "25%",
      },
      customRender: (creationDate: string) => {
        return (
          <Paragraph size="xsmall">
            {creationDate ? formatDate(creationDate) : ""}
          </Paragraph>
        );
      },
    },
    {
      Header: "Expires on",
      accessor: "expirationDate",
      disableSortBy: true,
      style: {
        width: "25%",
      },
      customRender: (_: unknown, row: FileInfo) => {
        return (
          <Paragraph size="xsmall">
            {row.creationDate
              ? formatDate(addDays(new Date(row.creationDate), 30).toString())
              : ""}
          </Paragraph>
        );
      },
    },
    {
      Header: "Description",
      accessor: "description",
      disableSortBy: true,
      style: {
        width: "30%",
      },
      customRender: (_: unknown, { metadata }: FileInfo) => {
        return <Paragraph size="xsmall">{metadata?.description}</Paragraph>;
      },
    },
    {
      Header: "Status",
      accessor: "status",
      disableSortBy: true,
      style: {
        width: "20%",
      },
      customRender: (_: unknown, { metadata }: FileInfo) => {
        return statusIcon[metadata?.status ?? FileStatus.FAILED];
      },
    },
    {
      Header: "Download",
      accessor: "url",
      disableSortBy: true,
      style: {
        width: "20%",
      },
      customRender: (url: string, { metadata }: FileInfo) => {
        const handleDownloadOutputFile = () => {
          window.open(url);
          SegmentService.bulkActionsFileDownloaded(fileTypeSegment);
        };

        return (
          <IconButton
            onClick={handleDownloadOutputFile}
            disabled={[FileStatus.PROCESSING].includes(metadata.status)}
            variant="inherit"
            icon={() => <Download size="medium" />}
            title="download"
          />
        );
      },
    },
  ];

  const actionTypeLabel = {
    LINK_USER: "Link user",
    CUSTOMER_USER: "Customer user",
  };

  const customerTableColumns = [
    {
      Header: "Created on",
      accessor: "creationDate",
      disableSortBy: true,
      style: {
        width: "15%",
      },
      customRender: (creationDate: string) => {
        return (
          <Paragraph size="xsmall">
            {creationDate ? formatDate(creationDate) : ""}
          </Paragraph>
        );
      },
    },
    {
      Header: "Expires on",
      accessor: "expirationDate",
      disableSortBy: true,
      style: {
        width: "15%",
      },
      customRender: (_: unknown, row: FileInfo) => {
        return (
          <Paragraph size="xsmall">
            {row.creationDate
              ? formatDate(addDays(new Date(row.creationDate), 30).toString())
              : ""}
          </Paragraph>
        );
      },
    },
    {
      Header: "Description",
      accessor: "description",
      disableSortBy: true,
      style: {
        width: "30%",
      },
      customRender: (_: unknown, { metadata }: FileInfo) => {
        return <Paragraph size="xsmall">{metadata?.description}</Paragraph>;
      },
    },
    {
      Header: "Type",
      accessor: "actionType",
      disableSortBy: true,
      style: {
        width: "15%",
      },
      customRender: (_: unknown, { metadata }: FileInfo) => {
        return (
          <Paragraph size="xsmall">
            {metadata?.actionType
              ? actionTypeLabel[metadata?.actionType]
              : "Customer user"}
          </Paragraph>
        );
      },
    },
    {
      Header: "Status",
      accessor: "status",
      disableSortBy: true,
      style: {
        width: "15%",
      },
      customRender: (_: unknown, { metadata }: FileInfo) => {
        return statusIcon[metadata?.status ?? FileStatus.FAILED];
      },
    },
    {
      Header: "Download",
      accessor: "url",
      disableSortBy: true,
      style: {
        width: "5%",
      },
      customRender: (url: string, { metadata }: FileInfo) => {
        const handleDownloadOutputFile = () => {
          window.open(url);
          SegmentService.bulkActionsFileDownloaded(fileTypeSegment);
        };

        return (
          <IconButton
            onClick={handleDownloadOutputFile}
            disabled={[FileStatus.PROCESSING].includes(metadata.status)}
            variant="inherit"
            icon={() => <Download size="medium" />}
            title="download"
          />
        );
      },
    },
  ];

  const tableColumns =
    activeTab === TabsBatchActionEnum.editAdminUsers
      ? adminTableColumns
      : customerTableColumns;

  return (
    <>
      <HeaderPage />
      <UpdateTimeDisplay>
        <Paragraph size="small">
          Last update: {formatDate(updateDate.toString(), "dd/MM/yyyy p")}
        </Paragraph>
      </UpdateTimeDisplay>
      <Table
        pagination={tablePagination.total > 0 ? tablePagination : false}
        cellHeight="tight"
        data={content}
        loading={isLoading}
        columns={tableColumns}
        emptyMessage={
          <EmptyState
            csvTemplate={
              activeTab === TabsBatchActionEnum.editAdminUsers
                ? csvTemplate
                : undefined
            }
            downloadTemplateFunction={
              activeTab === TabsBatchActionEnum.manageCustomerUsers
                ? () => setOpenFileDialog(true)
                : () =>
                    SegmentService.bulkActionsTemplateDownloadedHome(
                      FileType.EditAdminUser
                    )
            }
          />
        }
      />
      <DialogUploadFile
        open={openFileDialog}
        onClose={handleCloseFileDialog}
        onConfirm={onConfirmUploadFile}
        fileType={FileTypesByTab[activeTab]}
      />
    </>
  );
};
