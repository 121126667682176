import { FileValidated as ValidatedFile } from "@hexa-ui/components";

interface Action {
  name: string;
  action(): void;
}

export enum FileTypes {
  Authorization = "AUTHORIZATION",
  Invitation = "INVITATION",
}

export interface FileUploadErrors {
  message: string;
  action?: Action;
}

export interface DialogUploadFileProps {
  open: boolean;
  fileType: FileTypes;
  onClose(): void;
  onConfirm(fileName: string): void;
}

export type CustomerUserActionType = "CUSTOMER_USER" | "LINK_USER";

export interface IUploaderFileProps {
  handleOnChangeFile: ([preValidatedFile]: ValidatedFile[]) => void;
  file?: File;
  fileUploadError?: FileUploadErrors;
  setFileDescription: (description: string) => void;
  onClearFileField: () => void;
}
